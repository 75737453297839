<template>
    <div class="maincontent">
        <div class="container">
            <div class="row d-flex justify-content-center mt-4 description">
                <div class="col-12 col-md-8">
                    <div class="row">
                        <div class="col-12">
                            <h3>Grundrisse (Etage)</h3>
                            <span class="text-muted">Nachfolgenden sind die verschiedenen Etagen-Grundrisse des Wohnkomplexes dargestellt.</span>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 views">
                            <h3>Erdgeschoss Haus 1</h3>
                            <img class="imageView" src="@/assets/Bauplaene/GesamtansichtEtage/EG_Haus1.jpg" alt="Erdgeschoss Haus 1">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 views">
                            <h3>Erdgeschoss Haus 2</h3>
                            <img class="imageView" src="@/assets/Bauplaene/GesamtansichtEtage/EG_Haus2.jpg" alt="Erdgeschoss Haus 2">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 views">
                            <h3>Erdgeschoss Haus 3</h3>
                            <img class="imageView" src="@/assets/Bauplaene/GesamtansichtEtage/EG_Haus3.jpg" alt="Erdgeschoss Haus 3">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 views">
                            <h3>1. Obergeschoss Haus 1</h3>
                            <img class="imageView" src="@/assets/Bauplaene/GesamtansichtEtage/1Etage_Haus1.jpg" alt="1. Obergeschoss Haus 1">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 views">
                            <h3>1. Obergeschoss Haus 2</h3>
                            <img class="imageView" src="@/assets/Bauplaene/GesamtansichtEtage/1Etage_Haus2.jpg" alt="1. Obergeschoss Haus 2">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 views">
                            <h3>1. Obergeschoss Haus 3</h3>
                            <img class="imageView" src="@/assets/Bauplaene/GesamtansichtEtage/1Etage_Haus3.jpg" alt="1. Obergeschoss Haus 3">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 views">
                            <h3>2. Obergeschoss Haus 1</h3>
                            <img class="imageView" src="@/assets/Bauplaene/GesamtansichtEtage/2Etage_Haus1.jpg" alt="2. Obergeschoss Haus 1">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 views">
                            <h3>2. Obergeschoss Haus 2</h3>
                            <img class="imageView" src="@/assets/Bauplaene/GesamtansichtEtage/2Etage_Haus2.jpg" alt="2. Obergeschoss Haus 2">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 views">
                            <h3>2. Obergeschoss Haus 3</h3>
                            <img class="imageView" src="@/assets/Bauplaene/GesamtansichtEtage/2Etage_Haus3.jpg" alt="2. Obergeschoss Haus 3">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 views">
                            <h3>3. Obergeschoss Haus 1</h3>
                            <img class="imageView" src="@/assets/Bauplaene/GesamtansichtEtage/3Etage_Haus1.jpg" alt="3. Obergeschoss Haus 1">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 views">
                            <h3>3. Obergeschoss Haus 2</h3>
                            <img class="imageView" src="@/assets/Bauplaene/GesamtansichtEtage/3Etage_Haus2.jpg" alt="3. Obergeschoss Haus 2">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 views">
                            <h3>3. Obergeschoss Haus 3</h3>
                            <img class="imageView" src="@/assets/Bauplaene/GesamtansichtEtage/3Etage_Haus3.jpg" alt="3. Obergeschoss Haus 3">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'BlueprintFloor',
  components: {
  },
    data (){
        return {
            search: true,
            filter: '',
            selectOption: '',
            selectLength: '',
        }
    },
    methods:{
        
    },
    computed:{
        
    },
    created(){
    }

}
</script>

<style scoped>
.imageView{
    max-width: 100%;
}
.description{
    font-size: 1.2rem;
}
.views{
    position: relative;
}
</style>